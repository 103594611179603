import {Fragment, useEffect, useRef, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import '@/assets/styles/main.scss';
import time from '@/utils/helpers/time';
import type { IPostList } from '@/interfaces/board-interfaces';
import axiosMain from '@/services/api/apis/api-board.ts';

/**
 * 메인의 post 목록 컴포넌트
 * @param param0 post 글 목록
 */
const PostList = ({ name, getLiHeight }: IPostList | any) => {
  const navigate = useNavigate();
  const refLi = useRef<any>(null);
  const [postList, setPostList] = useState();

  useEffect(() => {
    const li = document.querySelectorAll('li');

    axiosMain
      .getRecentPost({ size: 5, category: name })
      .then((response) => {
        console.log(response.data?.list);
        setPostList(response.data?.list);
      })
      .catch((error: Error) => {
        console.log(error.message);
      });

    getLiHeight(li[0]?.clientHeight);
  }, []);

  const showContent = (event: any, data: any) => {
    const contents = event.target.closest('.main-li');

    if (contents.classList.contains('detail')) {
      contents.classList.remove('detail');
    } else {
      contents.classList.add('detail');
    }

    navigate(`detail/${data.idx}`, {
      state: {
        idx: data.idx,
        main: data.subject,
        tags: data.tags,
        pointer: {
          width: contents.getBoundingClientRect().width,
          height: contents.getBoundingClientRect().height,
          top: contents.getBoundingClientRect().top,
          left: contents.getBoundingClientRect().left,
        },
      },
    });
  };

  return (
    <div className="post-list">
      <div className="category-name">{name}</div>
      <ul ref={refLi}>
        {postList &&
          Object.entries(postList).map(([key, listData]: any) => {
            return (
              <Fragment key={key}>
                <li className="main-li" onClick={(e) => showContent(e, listData)}>
                  <div>
                    <div className="post-title">{decodeURIComponent(listData.title)}</div>

                    <div className="date-board">
                      <div className="post-date">
                        {time.convertDateFormat('YYYY MM DD ddd', listData.regdate)}
                      </div>
                    </div>
                  </div>
                </li>
              </Fragment>
            );
          })
        }
      </ul>
    </div>
  );
};

export default PostList;
