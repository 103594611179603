interface Props {
  isMobile: boolean;
  showMenu: () => void;
}

const Navigation = ({ isMobile, showMenu }: Props) => {
  return (
    <div className="icon-menu" onClick={isMobile ? () => showMenu() : undefined}>
      {isMobile && <i className="fa-sharp-duotone fa-solid fa-bars"></i>}
    </div>
  );
};

export default Navigation;
