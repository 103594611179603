import { useEffect, useState } from 'react';
// import axiosMain from '@/services/api/apis/api-board';
import { blogMain as Styled } from '@/styles';
import PostList from '@/components/blog/main/post-list';
// import type { IPostExtend } from '@/interfaces/board-interfaces';
import apiBoard from '@/services/api/apis/api-board';
// import 'assets/styles/main.scss';

const Main = () => {
  // const [postList, setPostList] = useState([] as IPostExtend[]);
  const [liHeight, setLiHeight] = useState<number>(200);
  const [categories, setCategories] = useState([]);
  // const [layoutHeight, setLayoutHeight] = useState(470);

  const getLiHeight = (height: number) => {
    if (height !== undefined) setLiHeight(+height);
  };

  useEffect(() => {
    // const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    //
    // let size = 5;
    // if (isMobile) {
    //   size = 3;
    // }

    apiBoard.getMenuItems().then((menuList) => {
      setCategories(menuList.data?.list);
    });
  }, [liHeight]);

  return (
    <Styled.MainComponent>
      <Styled.TopHeader>
        <Styled.SubText>하루 한 두 시간은 좀 그렇고..</Styled.SubText>
        <Styled.MainText>
          그냥&nbsp;<Styled.MainTextSpan>생각</Styled.MainTextSpan>&nbsp;날 때마다&nbsp;
          <Styled.MainTextSpan>기록</Styled.MainTextSpan> 하는 곳
        </Styled.MainText>
      </Styled.TopHeader>

      <Styled.PostsList>
        {
          categories && categories.map((category) => {
            return (<PostList key={ category } name={category} getLiHeight={getLiHeight} />);
          })
        }
      </Styled.PostsList>
    </Styled.MainComponent>
  );
};

export default Main;
