export const breakPoints = {
  mobile: {
    start: '280px',
    end: '767px',
  },
  tablet: {
    start: '768px',
    end: '1450px',
  },
  desktop: {
    start: '1451px'
  }
}
