import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import '@/assets/styles/layout.scss';
import axios from 'axios';
import Logo from '@/components/blog/common/header/logo';
import Menu from '@/components/blog/common/header/navigation';

const Header = () => {
  const location = useLocation();
  const scroll = useRef<HTMLDivElement | null>(null);
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  // 스크롤을 이동하면 헤더 아래에 스크롤바를 표시할 넓이를 조절한다.
  window.addEventListener('scroll', () => {
    const scrollLocation = document.documentElement.scrollTop; // 현재 스크롤바 위치
    const windowHeight = window.innerHeight; // 스크린 창
    const fullHeight = document.body.scrollHeight; //  margin 값은 포함 x
    const scrollWidth = `${Math.ceil((scrollLocation / (fullHeight - windowHeight)) * 100)}%`;

    if (scroll.current) scroll.current.style.width = scrollWidth;
  });

  useEffect(() => {
    if (import.meta.env.MODE !== 'development') {
      axios.get('https://api.ip.pe.kr/json').then((connectedIp) => {
        const formData = new FormData();
        formData.append('ip', connectedIp.data.ip);
      });
    }
  }, []);

  useEffect(() => {
    console.log(location.pathname)
    if (scroll.current)  scroll.current.style.width = '0';
  }, [location.pathname]);

  const showMenu = () => {
    const menuElement = Array.from(
      document.getElementsByClassName('menu') as HTMLCollectionOf<HTMLElement>,
    )[0];
    menuElement.style.display = menuElement.style.display === 'block' ? 'none' : 'block';
  };

  return (
    <header>
      <div className="header-area">
        <Logo />
        <Menu isMobile={isMobile} showMenu={showMenu} />
      </div>

      <div className="scroll-bar">
        <div className="inner-scroll" ref={scroll}></div>
      </div>
    </header>
  );
};

export default Header;
