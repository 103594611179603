import '@/assets/styles/menu.scss';
import {useEffect, useState} from 'react';
import axios from 'axios';
import apiBoard from '@/services/api/apis/api-board.ts';

const Menu = () => {
  const [icon, setIcon] = useState<string | undefined>();
  const [temp, setTemp] = useState('');
  const [cityName, setCityName] = useState('');

  useEffect(() => {
    if (import.meta.env.MODE !== 'development') {
      axios.get('https://api.ip.pe.kr/json').then(connectedIp => {
        console.log(connectedIp.data.ip);
        const formData = new FormData();
        formData.append('ip', connectedIp.data.ip);

        apiBoard.getLocationInfo(formData)
          .then((res) => {
            axios.get(`https://api.openweathermap.org/data/2.5/weather?lat=${res.data.list.latitude}&lon=${res.data.list.longitude}&exclude=minutely,hourly&appid=0a9b6eb452adb6e3dde30ebd42f545f2&units=metric&lang=kr`)
              .then((resWeather: any) => {
                const myJson = resWeather.data;
                const { icon } = myJson.weather[0];
                const temp = myJson.main.temp;

                setIcon(icon);
                setTemp(`${Math.round(temp)}˚`);
                setCityName(myJson.name);

              }).catch((error: any) => {
              console.log(error);
            });
          })
      });
    }
  }, []);
  return (
    <div className="weather">
      <div className="icon">
        {
          icon
            ? <img src={`src/assets/images/weather/${icon}@2x.png`} alt="icon-weather"/>
            : undefined
        }
      </div>

      <div className="city-name">{cityName}</div>
      <div className="temp">
        <span>{temp}</span>
      </div>
    </div>
  );
};

export default Menu;
