import axios from '../index';

const apiBoard = {
  getLocationInfo: (ipAddress: FormData) => axios.requestGet('/location', ipAddress),

  /**
   * 메인 화면의 post 목록을 가져온다.
   * @returns api 통신 결과 값
   */
  getRecentPost: (size: { size: number; category?: string; }) => axios.requestGet('/recent-post', size),

  /**
   * post 게시글의 상세 내용을 가져온다.
   * @param data api 요청 시 전송할 데이터
   * @returns api 통신 결과 값
   */
  postPostDetail: (data: object) => axios.requestPost('/post-detail', data),

  /**
   * 게시판의 글 목록을 가져온다.
   * @param formData
   * @returns api 통신 결과 값
   */
  boardList: (formData: object) => axios.requestPost('/board-list', formData),

  getMenuItems: () => axios.requestGet('/menu-items'),

  getTags: (data: object | null = null) => axios.requestGet('/admin/category-list', data),

  postAddCategory: (res: object) => axios.requestPost('/admin/add-category', res),
};

export default apiBoard;
